.flexContainer {
  display: flex;
  justify-content: space-between;
  padding: 13% 8% 6% 8%;
}

.moduleContainer {
  width: 17%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.whiteCircle {
  background-color: white;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
}
.moduleImage {
  width: 45%;
  height: 10vh;
}
.title {
  font-size: 15px;
  line-height: 19px;
  height: 4vh;
  margin-top: 4vh;
  text-align: center;
  color: #2e5f98;
}
.textInfo {
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #2e5f98;
}

@media only screen and (max-width: 768px) {
  .flexContainer {
    flex-direction: column;
    .moduleContainer {
      width: 100%;
      flex-direction: row;
      .moduleImage {
        width: 70px;
        padding-right: 2em;
      }
      .cardBody {
        display: flex;
        flex-direction: column;
        .title {
          text-align: start;
          margin-bottom: 0.5rem;
          height: auto;
        }
        .textInfo {
          text-align: start;
          margin-top: 0;
        }
      }
    }
  }
}
