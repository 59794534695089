.clientesContainer {
  display: flex;
  padding: 0 5% 0 8%;
  margin-bottom: 5vh;
  align-items: center;
}
.txtContainer {
  width: 35%;
}
.logosClientes {
  width: 65%;
}
.titleClientes {
  color: #2e5f98;
  font-weight: 700;
  font-size: 44px;
  margin-top: 10vh;
  margin-bottom: 15px;
}
.orangeDivisor {
  background-color: #e5590a;
  width: 45px;
  height: 7px;
}
.textQuees {
  width: 85%;
  text-align: justify;
  color: #2e5f98;
  font-size: 15px;
}
.demo {
  font-weight: 700;
}
.textQuees a {
  color: #2e5f98;
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
}
.textQuees a:hover {
  cursor: pointer;
}
.logos01 img {
  margin: 10px 0;
  width: 250px;
}
.logos01 {
  margin: 50px 0 30px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.logos02 img {
  margin: 10px 0;
  width: 200px;
}
.logos02 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .clientesContainer {
    flex-direction: column;
    .txtContainer {
      width: 100%;
      .textQuees {
        width: 100%;
      }
      .titleClientes {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .logosClientes {
      width: 100%;
      .logos01 {
        justify-content: space-between;
        margin-bottom: 0;
        img {
          width: 33%;
        }
      }
      .logos02 {
        justify-content: space-evenly;
        img {
          width: 45%;
        }
      }
    }
  }
}
