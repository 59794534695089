.blueSection {
  background-color: #2e5f98;
  width: 42%;
  height: 115vh;
  margin-top: -125px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 8%;
}

h1 {
  margin-top: 1vh;
  color: white;
  font-size: 50px;
  line-height: 50px;
  font-weight: 600;
}

.buttonDemo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 45px;
  margin-top: 1.5vh;
  background-color: #e5590a;
  color: white;
  border-radius: 40px;
  text-align: center;
}
.buttonDemo:hover {
  cursor: pointer;
  font-weight: 700;
  box-shadow: 0px 0px 16px -1px rgba(229, 89, 10, 1);
}
.despachoImg {
  background-image: url(../../../assets/parcel-boxes-in-cargo-cart-delivery-service-JRAA53J.jpg);
  position: absolute;
  width: 90%;
  height: 86%;
  bottom: 0;
  right: 0;
  width: 60%;
  border-radius: 0 180px 0 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  background-size: cover;
}

.orangeElement {
  background-color: #e5590a;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 80vh;
  height: 570px;
  width: 145px;
  border-radius: 110px 0 0 0;
}

@media only screen and (max-width: 768px) {
  .blueSection {
    background-color: #e5590a;
    width: auto;
    height: 55vh;
    margin-top: 0px;
    padding: 12% 8%;
    justify-content: center;
    h1 {
      font-size: 34px;
      line-height: 45px;
      margin-top: 0;
    }
  }
  .buttonDemo {
    background-color: #2e5f98;
    width: 180px;
    margin-top: 0;
  }
  .despachoImg {
    display: none;
  }
  .orangeElement {
    display: none;
  }
}
