.queEsContainer {
  /* padding: 0 8%;
    margin: 5.7% 0; */
}
.queEsContainer h2 {
  color: #2e5f98;
  font-weight: 700;
  font-size: 44px;
  margin-top: 13vh;
  margin-bottom: 15px;
  padding: 0 8%;
}

.textQuees {
  width: 38%;
  text-align: justify;
  color: #2e5f98;
  font-size: 15px;
}
.queEsContainer a {
  color: #2e5f98;
  font-size: 15px;
  font-weight: 700;
  margin-left: -15px;
  text-decoration: none;
}
.demo:hover {
  cursor: pointer;
  color: white;
  font-weight: normal;
}
.queEsImagen img {
  display: none;
}
.textContainer {
  padding: 0 8%;
}

.demoContainer {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border-radius: 20px;
  width: 9.5em;
  padding: 5px 0;
}
.demoContainer:hover {
  background-color: #2e5f98;
  cursor: pointer;
}
.demoContainer p {
  margin: 0;
}
.acontainer {
  display: inline-block;
  margin: 0;
}
.acontainer:hover {
  color: white;
  font-weight: normal;
}

.orangeDivisor {
  background-color: #e5590a;
  width: 45px;
  height: 7px;
}

.greyBkg {
  background-color: #ebebeb;
  width: 100%;
  height: 80%;
}

.queEsImg {
  background-image: url(../../../assets/a-smiling-person-wearing-an-orange-t-shirt-and-a-n-9M5R2VS.jpg);
  position: absolute;
  margin-top: -15vh;
  width: 40%;
  height: 45%;
  right: 8%;
  margin-bottom: -5px;
  border-radius: 120px 0 0 0;
  background-size: cover;
  z-index: 1;
  box-shadow: 18px 18px 0px 0px rgba(255, 255, 255, 1);
}
.orangeContainer {
  background-color: #e5590a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  width: 100%;
}

.orangeContainer p {
  color: white;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .queEsContainer {
    h2 {
      font-size: 30px;
      line-height: 40px;
      margin-top: 9vh;
    }
  }
  .queEsImg {
    display: none;
  }
  .textQuees {
    width: 100%;
  }
  .queEsImagen {
    width: 100%;
    img {
      margin-top: 30px;
      width: 100%;
      border-radius: 100px 0 0 0;
    }
  }
  .orangeContainer {
    height: 13vh;
    p {
      font-size: 1rem;
      line-height: 24px;
      padding: 0 2rem;
    }
  }
}
